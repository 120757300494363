import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_6/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
      hoverDot: mdx(
      frontmatter: {
        language: { eq: "DE" }
        title: { eq: "pilsudski-gang-interactive-photo" }
      }
    ) {
      exports {
        hoverInfo {
          positionLeft
          positionTop
          insideText
          identifier
        }
      }
    },
    jozefHaller:  mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "pilsudski-gang-interactive-photo"}, selector: {eq: "jozefHaller"}}) {
      body
    },
    jozefPilsudski: mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "pilsudski-gang-interactive-photo"}, selector: {eq: "jozefPilsudski"}}) {
      body
    },
    tadeuszRozwadowski: mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "pilsudski-gang-interactive-photo"}, selector: {eq: "tadeuszRozwadowski"}}) {
      body
    },
    wincentyWitos: mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "pilsudski-gang-interactive-photo"}, selector: {eq: "wincentyWitos"}}) {
      body
    },
    kazimierzSosnkowski: mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "pilsudski-gang-interactive-photo"}, selector: {eq: "kazimierzSosnkowski"}}) {
      body
    },
    ignacyDaszynski: mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "pilsudski-gang-interactive-photo"}, selector: {eq: "ignacyDaszynski"}}) {
      body
    },
    caption:   mdx(frontmatter: {language: {eq: "DE"}, title: {eq: "slide-4-6-1"}}) {
      body
  }
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
